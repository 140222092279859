import React, { useEffect, useRef } from 'react';
import { AppContext } from '../../context/AppContext'

export default function HolidayShop() {
  const { holiday, products } = React.useContext(AppContext);
  const fannyPackProduct = products.find(product => product.shopifyId === '8694558130420');
  const initialized = useRef(false);
  
  useEffect(() => {
    if (initialized.current) return;
    console.log('Holiday array:', holiday);
    console.log('Fanny pack product:', fannyPackProduct);

    const scriptURL = "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.onload = ShopifyBuyInit;
      document.body.appendChild(script);
    }

    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: 'carhopshop.myshopify.com',
        storefrontAccessToken: 'd61f6d4f2bdba47b23e8346ec85c57d5',
      });

      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        console.log('Shopify UI ready');
        initialized.current = true;
        
        const baseOptions = {
          moneyFormat: "${{amount}}",
          options: {
            product: {
              iframe: false,
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                },
                title: {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                  "cursor": "pointer",
                },
                price: {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                },
                button: {
                  "@media (max-width: 768px)": {
                    "margin-top":"-20px !important",
                  },
                  ":hover": {
                    "background-color": "#ffffff",
                    "color": "#008ad8",
                    "border": "3px solid #008ad8",
                  },
                  "background-color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                  "color": "#ffffff",
                  "border": "3px solid #008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    "font": "bold 20px/1 'Poppins', sans-serif",
                    "color": "#ffffff",
                    "border": "3px solid #008ad8",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              "buttonDestination": "modal",
              "contents": {
                "img": true,
                "imgWithCarousel": false,
                "title": false,
                "price": false,
                "options": false,
                "button": false,
                "description": false
              },
              "templates": {
                "img": `
                  <div class="{{data.classes.product.img}}" style="cursor: pointer;" onclick="this.querySelector('.shopify-buy__btn').click()">
                    <img src="{{data.currentImage.src}}" class="card-img" />
                    <button class="shopify-buy__btn" style="display: none;"></button>
                  </div>
                `,
                "title": `
                  <div class="{{data.classes.product.title}}" style="cursor: pointer;" onclick="this.querySelector('.shopify-buy__btn').click()">
                    <h3 class="{{data.classes.product.title}}">{{data.title}}</h3>
                    {{#data.selectedVariant.price}}
                    <div class="{{data.classes.product.price}}">{{data.selectedVariant.formattedPrice}}</div>
                    {{/data.selectedVariant.price}}
                    <button class="shopify-buy__btn" style="display: none;"></button>
                  </div>
                `,
                "button": `
                  <button class="{{data.classes.product.button}} shopify-buy__btn" style="width: 100%;">{{data.buttonText}}</button>
                `
              }
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "title": true,
                "price": true,
                "options": true,
                "button": true,
                "buttonWithQuantity": true,
                "description": true
              },
              "styles": {
                "modal": {
                  "background-color": "#ffffff",
                  "padding": "20px",
                  "border-radius": "30px",
                  "max-width": "1200px",
                },
                "overlay": {
                  "background-color": "rgba(0, 0, 0, 0.5)"
                },
                "img": {
                  "max-width": "100%",
                  "height": "auto",
                  "margin-bottom": "20px"
                },
                "imgWithCarousel": {
                  "max-width": "100%",
                  "height": "auto",
                  "margin-bottom": "20px"
                },
                "price": {
                  "color": "#008ad8",
                  "font": "bold 24px/1 'Poppins', sans-serif",
                },
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin": "0",
                    "color": "#008ad8",
                    "font": "bold 48px/1 'Poppins', sans-serif",
                    "text-transform": "uppercase",
                    "border-radius": "30px",
                  },
                },
                "options": {
                  "margin-top": "15px"
                },
                description: {
                  "font": "normal 14px/1.5 'Poppins', sans-serif",
                  "text-transform": "none",
                },
                quantityInput: {
                  "font": "normal 18px/1 'Poppins', sans-serif",
                  "height": "44px",
                  "border-radius": "30px",
                  "border": "3px solid #008ad8",
                  "color": "#008ad8",
                  "margin-right": "0",
                  "display": "inline-block",
                  "vertical-align": "top"
                },
                button: {
                  ":hover": {
                    "background-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "background-color": "#008ad8",
                  "font": "bold 16px/1 'Poppins', sans-serif",
                  "color": "#ffffff",
                  "border": "3px solid #008ad8 !important",
                  "height": "44px",
                  ":focus": {
                    "background-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                  "display": "inline-block",
                  "vertical-align": "top",
                },
                buttonWrapper: {
                  "display": "flex",
                  "align-items": "center",
                  "gap": "10px",
                },
              },
              "text": {
                "button": "Add to cart",
              },
              "order": ["img", "imgWithCarousel", "title", "price", "buttonWithQuantity", "options", "description"],
            },
            "option": {},
            "cart": {
              "styles": {
                "title": {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                },
                "button": {
                  ":hover": {
                    "background-color": "#008ad8",
                    "border-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "background-color": "#ffffff",
                  "border": "3px solid #008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                  "color": "#008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              "text": {
                "total": "Subtotal",
                "button": "Checkout",
              },
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "background-color": "#e40345",
                  ":hover": {
                    "background-color": "#cd033e",
                  },
                  ":focus": {
                    "background-color": "#cd033e",
                  },
                },
              },
            },
          },
        };

        // Create components for products in the holiday array
        holiday.forEach(product => {
          if (product.shopifyId === undefined) return;
          
          const imageNode = document.getElementById(`product-image-${product.shopifyId}`);
          const titleNode = document.getElementById(`product-title-${product.shopifyId}`);
          
          if (imageNode && titleNode) {
            console.log('Creating components for holiday product:', product.shopifyId);
            console.log('Image node:', imageNode);
            console.log('Title node:', titleNode);

            // Create image component with custom template
            const imageOptions = {
              ...baseOptions,
              options: {
                ...baseOptions.options,
                product: {
                  ...baseOptions.options.product,
                  contents: {
                    ...baseOptions.options.product.contents,
                    img: true,
                    price: false,
                    button: false
                  }
                }
              }
            };

            // Create title button options
            const titleButtonOptions = {
              ...baseOptions,
              options: {
                ...baseOptions.options,
                product: {
                  ...baseOptions.options.product,
                  contents: {
                    ...baseOptions.options.product.contents,
                    img: false,
                    button: false,
                    title: true,
                    price: true
                  },
                  buttonDestination: "modal",
                  templates: {
                    ...baseOptions.options.product.templates
                  }
                }
              }
            };

            // Create image component
            ui.createComponent("product", {
              id: product.shopifyId,
              node: imageNode,
              ...imageOptions
            });

            // Create title button component
            ui.createComponent("product", {
              id: product.shopifyId,
              node: titleNode,
              ...titleButtonOptions
            });
          }
        });

        // Create components for the fanny pack product
        if (fannyPackProduct && fannyPackProduct.shopifyId) {
          const imageNode = document.getElementById(`product-image-${fannyPackProduct.shopifyId}`);
          const buttonNode = document.getElementById(`product-button-${fannyPackProduct.shopifyId}`);
          
          if (imageNode && buttonNode) {
            console.log('Creating components for fanny pack:', fannyPackProduct.shopifyId);
            console.log('Image node:', imageNode);
            console.log('Button node:', buttonNode);

            // Create image component with custom template
            const imageOptions = {
              ...baseOptions,
              options: {
                ...baseOptions.options,
                product: {
                  ...baseOptions.options.product,
                  contents: {
                    ...baseOptions.options.product.contents,
                    img: true,
                    price: false,
                    button: false
                  }
                }
              }
            };

            // Create button options
            const buttonOptions = {
              ...baseOptions,
              options: {
                ...baseOptions.options,
                product: {
                  ...baseOptions.options.product,
                  contents: {
                    ...baseOptions.options.product.contents,
                    img: false,
                    button: true,
                    title: false,
                    price: false
                  },
                  buttonDestination: "modal",
                  text: {
                    button: "SHOP IT"
                  },
                  templates: {
                    ...baseOptions.options.product.templates
                  }
                }
              }
            };

            // Create image component
            ui.createComponent("product", {
              id: fannyPackProduct.shopifyId,
              node: imageNode,
              ...imageOptions
            });

            // Create button component
            ui.createComponent("product", {
              id: fannyPackProduct.shopifyId,
              node: buttonNode,
              ...buttonOptions
            });
          }
        }
      });
    }

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }

  }, [holiday, fannyPackProduct]);

  return (
    <>
      <div id="static" className="light-blue-bkg">
        <div className="static-container">
          <div className="cards">
            <div className="card flex-center">
              <img style={{maxWidth: '850px', width: '100%', marginBottom: '2rem', borderRadius: '0'}} src='./static/holiday-headline.svg' width="1024" alt="Fun Is On The Menu - SWEEPSTAKES Powered by TripAdvisor" />
              <p style={{maxWidth: '610px'}}>The perfect gifts for your SONIC-obsessed friends and family are all right here. Shop ornaments, mugs and more.</p>
            </div>
          </div>
          <div className="cards cards-auto">
            {holiday.map((product) => (
              <div className="card card-product" key={product.id}>
                {product.shopifyId ? (
                  <>
                    <div id={`product-image-${product.shopifyId}`} className="product-component"></div>
                    <div id={`product-title-${product.shopifyId}`} className="product-component"></div>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
