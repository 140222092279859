import * as THREE from 'three';
import { extend } from '@react-three/fiber';

const SparkleMaterial = new THREE.ShaderMaterial({
  uniforms: {
    viewVector: { type: 'v3', value: new THREE.Vector3() },
    uTime: { type: 'f', value: 0 },
    uSparkleScale: { type: 'f', value: 150.0 }, // Increased scale for more sparkles
    uSparkleSpeed: { type: 'f', value: 4.0 }, // Increased speed for more dynamic sparkles
    uSparkleIntensity: { type: 'f', value: 2.0 }, // Increased intensity
    uSparkleColor1: { type: 'v3', value: new THREE.Vector3(0.8, 0.9, 1.0) }, // Brighter blue tint
    uSparkleColor2: { type: 'v3', value: new THREE.Vector3(1.0, 1.0, 1.0) }, // Pure white
    uDensity: { type: 'f', value: 1.5 }, // Increased density
    uOpacity: { type: 'f', value: 1.0 }
  },
  vertexShader: /*glsl*/`
    uniform vec3 viewVector;
    varying vec3 vPosition;
    varying vec3 vNormal;
    varying float vIntensity;
    varying vec3 vViewDir;
    
    void main() {
      vPosition = position;
      vNormal = normal;
      
      // Calculate view-dependent effects
      vec3 worldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      vViewDir = normalize(cameraPosition - worldPosition);
      vec3 actual_normal = normalize(mat3(modelMatrix) * normal);
      vIntensity = pow(dot(vViewDir, actual_normal), 2.0); // Increased power for sharper highlights
      
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: /*glsl*/`
    uniform float uTime;
    uniform float uSparkleScale;
    uniform float uSparkleSpeed;
    uniform float uSparkleIntensity;
    uniform vec3 uSparkleColor1;
    uniform vec3 uSparkleColor2;
    uniform float uDensity;
    uniform float uOpacity;
    
    varying vec3 vPosition;
    varying vec3 vNormal;
    varying float vIntensity;
    varying vec3 vViewDir;

    // Improved hash function for more random sparkles
    float hash(vec3 p) {
      p = fract(p * vec3(443.8975, 397.2973, 491.1871));
      p += dot(p.zxy, p.yxz + 19.19);
      p *= p + vec3(31.0, 17.0, 37.0);
      return fract((p.x + p.y + p.z) * 0.1);
    }

    // Enhanced sparkle function
    float sparkle(vec3 pos, float time, float scale) {
      vec3 gridPos = floor(pos * scale);
      vec3 fractPos = fract(pos * scale);
      
      // Multiple random offsets for more varied animation
      float offset1 = hash(gridPos) * 6.283;
      float offset2 = hash(gridPos + 1.234) * 6.283;
      float freq1 = mix(0.8, 1.2, hash(gridPos + 5.678));
      float freq2 = mix(1.3, 1.7, hash(gridPos + 9.012));
      
      // Combine multiple animated sparkles
      float sparkleTime1 = time * uSparkleSpeed * freq1 + offset1;
      float sparkleTime2 = time * uSparkleSpeed * freq2 + offset2;
      float twinkle = (sin(sparkleTime1) * 0.5 + 0.5) * (sin(sparkleTime2) * 0.5 + 0.5);
      
      // Sharper sparkle points
      float d = length(fractPos - 0.5) * (2.0 - uDensity);
      float sparklePoint = smoothstep(0.3, 0.1, d); // Adjusted for sharper points
      
      // Enhanced variation
      float intensityVar = mix(0.9, 1.5, hash(gridPos + 5.678));
      
      return sparklePoint * pow(twinkle, 2.0) * pow(hash(gridPos), 1.5) * intensityVar;
    }

    void main() {
      vec3 pos = vPosition * uSparkleScale;
      
      // Multi-layered sparkles at different scales
      float s1 = sparkle(pos, uTime, 1.0);
      float s2 = sparkle(pos * 1.5, uTime, 2.0) * 0.6;
      float s3 = sparkle(pos * 2.0, uTime, 4.0) * 0.3;
      float s4 = sparkle(pos * 3.0, uTime, 8.0) * 0.15;
      
      // Combine layers with enhanced intensity
      float finalSparkle = (s1 + s2 + s3 + s4) * uSparkleIntensity;
      
      // Sharper sparkle definition
      finalSparkle = pow(finalSparkle, 2.0);
      
      // Enhanced color mixing for more dramatic effect
      vec3 sparkleColor = mix(
        uSparkleColor1,
        uSparkleColor2,
        pow(finalSparkle, 1.5)
      );
      
      // Enhanced fresnel effect for edge highlights
      float fresnel = pow(1.0 - dot(vViewDir, vNormal), 4.0);
      finalSparkle *= mix(1.0, 2.0, fresnel);
      
      // Combine with view-dependent intensity
      gl_FragColor = vec4(sparkleColor, finalSparkle * uOpacity * vIntensity);
    }
  `,
  side: THREE.DoubleSide,
  blending: THREE.AdditiveBlending,
  transparent: true,
  depthWrite: false
});

extend({ SparkleMaterial });

export default SparkleMaterial;
